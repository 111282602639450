import React from 'react';

const IconOpenstack = () => (
  <svg
    viewBox="0 -2.5 256 256"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    fill="currentColor">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <path d="M57.8196917,170.722795 L57.8196917,179.878808 C57.8196917,186.98261 63.5764029,192.739322 70.6802055,192.739322 L70.6802055,192.739322 L185.330319,192.739322 C192.434121,192.739322 198.190832,186.98261 198.190832,179.878808 L198.190832,179.878808 L198.190832,170.722795 L256,170.722795 L256,226.77443 C256,239.855951 245.296937,250.559013 232.215416,250.559013 L232.215416,250.559013 L23.7845838,250.559013 C10.7030627,250.559013 0,239.855951 0,226.77443 L0,226.77443 L0,170.722795 L57.8196917,170.722795 Z M57.8091675,96.3696608 L57.8091675,154.178828 L0,154.178828 L0,96.3696608 L57.8091675,96.3696608 Z M256,96.3696608 L256,154.178828 L198.190832,154.178828 L198.190832,96.3696608 L256,96.3696608 Z M232.215416,0 C245.296937,0 256,10.7030627 256,23.7951079 L256,23.7951079 L256,79.8362179 L198.190832,79.8362179 L198.190832,70.6802055 C198.190832,63.5764029 192.434121,57.8196917 185.330319,57.8196917 L185.330319,57.8196917 L70.6696814,57.8196917 C63.5658787,57.8196917 57.8091675,63.5764029 57.8091675,70.6802055 L57.8091675,70.6802055 L57.8091675,79.8362179 L0,79.8362179 L0,23.7845838 C0,10.7030627 10.7030627,0 23.7845838,0 L23.7845838,0 Z">
          {' '}
        </path>{' '}
      </g>{' '}
    </g>
  </svg>
);

export default IconOpenstack;
